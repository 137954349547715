.web-legos-image-edit:hover {
  opacity: .5;
  border: 2px solid black;
  cursor: pointer;
}

.web-legos-image-shadow {
  filter: drop-shadow(0px 0px 6px black);
}

.web-legos-image-round {
  border-radius: 50%;
}