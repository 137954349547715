.App {
  text-align: center;
  box-sizing: border-box;
  max-width: 100%;
  font-family: "Kiwi Maru", serif !important;
  font-style: normal !important;
}

.app-content {
  width: 100vw;
}

nav.custom {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin-bottom: -60px;
  z-index: 5;
  height: 60px;
  background-color: rgba(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
  gap: 2rem;
  padding-right: 2rem;
  justify-content: flex-end;
}

.loadable-transition {
  transition: all 1s ease;
}