.web-legos-responsive-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  gap: 1rem;
}

.wl-toggleable-section-container {
  width: 100%;
  height: 100%;
}

.wl-toggleable-section-content {
  transition: all 0.5s ease-in-out;
}

.wl-toggleable-section-container.wl-toggleable-section-editable.wl-toggleable-section-off {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wl-toggleable-section-container.wl-toggleable-section-editable.wl-toggleable-section-off .wl-toggleable-section-content {
  transform: scale(0.2);
  background-color: rgba(0,0,0,0.2);
  border: 1px solid rgba(0,0,0,0.5);
  opacity: 0.5;
  cursor: pointer;
}

.wl-toggleable-section-container.wl-toggleable-section-editable.wl-toggleable-section-on { /* Not implemented */ }

.wl-toggleable-section-container.wl-toggleable-section-ineditable.wl-toggleable-section-off { display: none; }

.wl-toggleable-section-container.wl-toggleable-section-ineditable.wl-toggleable-section-on { /* Not implemented */ }

.colored-shadow-box {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  padding: 1rem;
  position: relative;
}

.colored-shadow-box-shadow {
  display: block;
  content: '';
  position: absolute;
  top: 50px;
  left: 50px;
  width: calc(100% - 50px);
  height: 100%;
  border-radius: 20px 0 0 20px;
  z-index: -1;
  opacity: 0.2;
}

.colored-shadow-box-aligned-left {
 border-top-right-radius: 0; 
 border-bottom-right-radius: 0; 
}

.toggle-pane {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: height var(--transitionTime) ease;
}

.toggle-pane-item {
  width: 100%;
  transition: all var(--transitionTime) ease;
}

.toggle-pane-item-1 {
  height: var(--height1);
  transform: scaleY(var(--visibility1));
  z-index: var(--zIndex1);
}

.toggle-pane-item-2 {
  height: var(--height2);
  transform: scaleY(var(--visibility2));
  z-index: var(--zIndex2);
}